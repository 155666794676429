import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { Link } from "gatsby"

import ReactMarkdown from "react-markdown"
import Moment from "react-moment"

import Layout from "../components/layout"
import Footer from "../components/footer"

import waveReverse from "../images/wave_reverse.svg"

export const query = graphql`
  query BlogArticleQuery($id: String!) {
    strapiBlogArticle(strapiId: { eq: $id }) {
      strapiId
      title
      content
      published_at
      ogDescription
      image {
        url
      }
    }
  }
`

const Article = ({ data }) => {
  const article = data.strapiBlogArticle
  return (
    <Layout>
      <SEO
        title={article.title}
        description={article.ogDescription}
        image={article.image.url}
      />

      <div className="container mx-auto mt-12">
        <div className="px-5 md:p-0 max-w-xl mx-auto mt-5">
          <h1 className="font-bold text-3xl md:text-5xl leading-none">
            {article.title}
          </h1>
          <p className="text-sm text-gray-500 mt-3">
            Posted on{" "}
            <Moment format="MMM Do YYYY">{article.published_at}</Moment>
          </p>
        </div>

        <div className="px-5 md:p-0 max-w-xl mx-auto mt-5">
          <div className="blog-article">
            <ReactMarkdown source={article.content} />
          </div>
        </div>
      </div>

      <img className="w-full mt-24" src={waveReverse} alt="" />

      <section className="container mx-auto text-center px-5 md:px-0 mt-10 md:mt-0">
        <div className="bg-gray-100 w-full rounded-xl p-8 md:p-24">
          <h1 className="text-3xl md:text-5xl text-gray-700 font-bold leading-tight tracking-tight md:mx-0 animated fadeIn">
            Join leading <span className="wrap">agencies</span>,{" "}
            <span className="wrap">developers</span> &{" "}
            <span className="wrap">agents</span> already using Flow to scale and
            automate their digital presence
          </h1>
          <Link
            to="/book-demo"
            className="inline-block bg-orange-500 text-white rounded-lg p-3 cursor-pointer mt-5"
          >
            Book a demo <i className="fas fa-arrow-right ml-2"></i>
          </Link>
        </div>
      </section>

      <Footer />
    </Layout>
  )
}

export default Article
